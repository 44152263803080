import React, { useEffect, useState } from "react";
import { GlobalStyle } from "../assets/styles/globalStyles";
import {
	StyledSection,
	StyledSectionInner,
} from "../assets/styles/commonStyles";
import ModelosDetalleGrid from "../components/common/ModelosDetalleGrid";
import { connect } from "react-redux";
import API from "../classes/api";
import PromocionModeloSlider from "../components/common/PromocionModeloSlider";
import { getCarline, setSalesgroup } from "../redux/carlines/actions";
import ComponentLoading from "../components/common/ComponentLoading";
import Layout from "../components/common/Layout";
import { dataLayerPush } from "../classes/gtm";

const OpcionFuturoPage = ({
	location,
	carline,
	salesgroup,
	getCarline,
	setSalesgroup,
}) => {
	const [tipo, setTipo] = useState(null);
	const [carlineCodigo, setCarlineCodigo] = useState(null);
	const [salesgroupCodigo, setSalesgroupCodigo] = useState(null);
	const [ofertas, setOfertas] = useState(null);
	const [cols, setCols] = useState(null);

	useEffect(() => {
		// primero leer partes de la url
		if (location) {
			const pathParts = location.pathname.split("/");
			setTipo(pathParts[pathParts.length - 3]);
			setCarlineCodigo(pathParts[pathParts.length - 2]);
			setSalesgroupCodigo(pathParts[pathParts.length - 1]);
		}
	}, [location]);

	useEffect(() => {
		// hay tipo, carlineCodigo y salesgroupCodigo, cargar el carline de la api
		if (tipo && carlineCodigo && salesgroupCodigo) {
			getCarline({
				codigo: carlineCodigo,
				isEmpresa: false,
				ofertaTipo: "opcion-futuro",
			});
		}
	}, [tipo, carlineCodigo, salesgroupCodigo]);

	useEffect(() => {
		// cuando hay carline, buscar el salesgroup en el carline
		if (carline) {
			setSalesgroup(
				API.findSalesgroupFromCarline(carline, salesgroupCodigo),
			);
		}
	}, [carline]);

	useEffect(() => {
		// cuando hay salesgroup, buscar sus ofertas
		if (salesgroup) {
			setOfertas(API.findOfertasByTipo(salesgroup, tipo));
		}
	}, [salesgroup]);

	useEffect(() => {
		// cuando hay ofertas, determinar cuantas columnas hay que mostrar
		if (ofertas) {
			if (ofertas.length === 1) {
				setCols("1");
			} else if (ofertas.length === 2) {
				setCols("2");
			} else {
				setCols("3");
			}
		}
	}, [ofertas]);

	// gtm

	useEffect(() => {
		if (carline && salesgroup && ofertas) {
			const mejorOferta = API.findMejorOfertaFromSalesgroupByTipo("opcion-futuro", salesgroup);
			dataLayerPush({
				seccion: "promociones",
				pagina: "opcion-futuro",
				gama: carline.codigo,
				modelo: salesgroup.codigo,
				precio: mejorOferta ? mejorOferta.precio : "",
				utm_content: "",
				modelo_edicion: "",
				financiacion: "opcion-futuro",
			});
		}
	}, [carline, salesgroup, ofertas]);

	return (
		<Layout>
			<main>
				<GlobalStyle />

				{(!carline || !salesgroup || !ofertas || !cols) && (
					<StyledSection>
						<StyledSectionInner>
							<ComponentLoading />
						</StyledSectionInner>
					</StyledSection>
				)}

				{carline && salesgroup && ofertas && cols && (
					<>
						<StyledSection>
							<PromocionModeloSlider
								position="left"
								sliderItems={(() => {
									const banners = API.findBanners(
										carline,
										salesgroup,
										"opcion-futuro",
										false,
									);
									const items = banners.map((banner) => {
										return {
											id: banner.id,
											preTitle: `${
												salesgroup.nuevo ? "Nuevo" : ""
											} Audi ${carline.titulo} ${salesgroup.titulo}`,
											title: banner.trimlevel.titulo,
											price: banner.price
												? `${API.formatPrecio(banner.price)}€`
												: null,
											priceNote: "Con entrada. IVA incluído.",
											image: banner.imagenes?.secundaria?.l,
											featuresList: [
												{
													value: `Renting a 36 meses.`,
												},
												{
													value: "Todos los servicios incluidos.",
												},
												{
													value: "Equipamiento exclusivo.",
												},
											],
											buttons: (() => {
												if (banner.botones) {
													return banner.botones.map((boton) => {
														return {
															text: boton.etiqueta,
															url: boton.url,
															nuevaVentana: boton.nuevaVentana,
														};
													});
												} else return null;
											})(),
										};
									});
									return items;
								})()}
							/>
						</StyledSection>

						<StyledSection>
							<StyledSectionInner>
								<ModelosDetalleGrid
									cols={cols}
									carline={carline}
									salesgroup={salesgroup}
									ofertas={ofertas.sort((a, b) => {
										return a.oferta.precio - b.oferta.precio;
									})}
									mode="opcion-futuro"
								/>
							</StyledSectionInner>
						</StyledSection>
					</>
				)}
			</main>
		</Layout>
	);
};

const mapStateToProps = (state) => {
	const { carline, salesgroup } = state.carlines;
	return {
		carline,
		salesgroup,
	};
};

const mapDispatchToProps = {
	getCarline,
	setSalesgroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(OpcionFuturoPage);
